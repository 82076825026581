/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, MouseEvent } from 'react'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { Collection, InfoIs } from 'types'
import { Accordion, Button, ButtonSize, ButtonStyle, Loader } from 'components'
import {
  CHARTIS_SOURCES_IDS,
  handleLocateObject,
  deleteFavoriteCollection,
  FavoritesSignal,
  postFavoriteCollection,
  MapDataSignal,
} from 'services'

import './CollectionPanel.scss'

type Props = {
  collection: Collection
  onBack?: () => void
}

export default function CollectionPanel({ collection, onBack }: Props) {
  const [loading, setLoading] = useState(false)
  const favoriteId = FavoritesSignal.collections.value.find(col => col.id === collection.id)?.favorite_id

  const handleFavorite = async () => {
    await postFavoriteCollection(collection)
  }

  const handleLocate = (id: string, layer: string) => async (e: MouseEvent) => {
    e.stopPropagation()
    setLoading(true)
    await handleLocateObject(id, layer)
    setLoading(false)
  }

  const handleFocus = (id: string) => async () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id } }]
  }

  const getIsLayer = (is: InfoIs) => {
    if (is.type === 'POSTE') {
      return CHARTIS_SOURCES_IDS.poste
    }

    return CHARTIS_SOURCES_IDS.bal
  }

  return (
    <div className="collection-panel hide-scroll">
      <h3>
        {onBack && (
          <Button
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
        )}
        <p className="title flex-center">
          {collection.nom_collection_gep}
          {loading && <Loader variant="x-small" />}
        </p>
        {favoriteId
          ? <FavoriteSelectedIcon onClick={() => deleteFavoriteCollection(favoriteId)} />
          : <FavoriteIcon onClick={handleFavorite} />}
      </h3>
      <p className="code-geo">
        <b>Code Géographique : </b>
        {collection.code_geographique}
      </p>
      <p className="ouranos-id">
        <b>ID Ouranos : </b>
        {collection.jira_id}
      </p>
      <div className="separator" />
      <Accordion title="IS de la collection" defaultExpanded>
        {collection.infos_is.map((is, index) => (
          <>
            <div key={is.id} className="is-item">
              <p>
                <b>ID Ouranos : </b>
                {is.id}
              </p>
              <p>
                <b>Nom de l’IS : </b>
                <span>{is.libelle_long}</span>
              </p>
              <p>
                <b>Type : </b>
                {is.type}
              </p>
              <p>
                <b>Technologie : </b>
                {is.poste_technologie_libelle}
              </p>
              <p>
                <b>Ferrolocalisation : </b>
                {`${is.ligne_id} - ${is.localisation}`}
              </p>
              <div className="small-separator" />
              <p>
                <b>{'IHM : '}</b>
                <span>{is.poste_ihms.length ? 'Oui' : 'Aucune IHM Ouranos identifiée pour cette collection'}</span>
                {is.poste_ihms.length > 0
                  && is.poste_ihms.map((ihm, i) => (
                    <div key={ihm.id}>
                      <p>
                        <b>{'ID HM Ouranos : '}</b>
                        <span>{`${ihm.id}${(i + 1) < is.poste_ihms.length ? ', ' : ''}`}</span>
                      </p>
                      <p>
                        <b>Libellé IHM : </b>
                        <Button
                          style={ButtonStyle.borderLess}
                          text={`${ihm.libelle}${(i + 1) < is.poste_ihms.length ? ', ' : ''}`}
                          size={ButtonSize.medium}
                          inline
                          onClick={handleLocate(ihm.id, CHARTIS_SOURCES_IDS.ihm)}
                          disabled={loading}
                        />
                      </p>
                    </div>
                  ))}
              </p>
              <div className="small-separator" />
              <p>
                <b>Gaia : </b>
                {is.gaia_match ? 'Oui' : 'Non'}
              </p>
              {is.gaia_match && (
              <p>
                <b>Libellé Gaia : </b>
                <Button
                  style={ButtonStyle.borderLess}
                  text={is.poste_libelle_long || 'N/C'}
                  size={ButtonSize.medium}
                  inline
                  onClick={handleLocate(is.gaia_id, getIsLayer(is))}
                  disabled={!is.poste_libelle_long || loading}
                />
              </p>
              )}
              {is.gaia_match && (
              <p>
                <b>ID Gaia : </b>
                  {is.gaia_id || 'N/C'}
              </p>
              )}
            </div>
            {index < collection.infos_is.length - 1 && <div className="separator" />}
          </>
        ))}
      </Accordion>
      <div className="separator" />
      {(
        collection.collection_idf?.nom_collection_idf && (
          <>
            <Accordion title="Retours Chantiers" defaultExpanded>
              <p>
                <b>Etudes en cours : </b>
                {collection.collection_idf.nombre_etude_en_cours || 'Aucune'}
              </p>
              <p>
                <b>RC en attente: </b>
                {collection.collection_idf.nombre_en_attente_rc || 'Aucune'}
              </p>
              <p>
                <b>DC réalisables non lancés : </b>
                {collection.collection_idf.nombre_dc_non_commence || 'Aucune'}
              </p>
              <p>
                <b>DC bloqués : </b>
                {collection.collection_idf.nombre_dc_bloque || 'Aucune'}
              </p>
              <p>
                <b>DC en cours : </b>
                {collection.collection_idf.nombre_dc_non_termine || 'Aucune'}
              </p>
            </Accordion>
            <div className="separator" />
          </>
        )
      )}
      <Accordion title="Interactions" defaultExpanded>
        <div className="encadrants d-flex">
          <b>Encadrant(s) : </b>
          {collection.postes_encadrants.map(poste => (
            <Button
              key={poste.id}
              style={ButtonStyle.borderLess}
              text={poste.libelle_long}
              size={ButtonSize.medium}
              inline
              onClick={handleLocate(poste.id, CHARTIS_SOURCES_IDS.poste)}
              onMouseEnter={handleFocus(poste.id)}
              disabled={loading}
            />
          ))}
          {collection.bals_encadrants.map(bal => (
            <Button
              key={bal.id}
              style={ButtonStyle.borderLess}
              text={bal.libelle}
              size={ButtonSize.medium}
              inline
              onClick={handleLocate(bal.id, CHARTIS_SOURCES_IDS.bal)}
              disabled={loading}
            />
          ))}
          {collection.bals_encadrants.length === 0 && collection.postes_encadrants.length === 0 && 'Aucun'}
        </div>
      </Accordion>
      <div className="separator" />
      <Accordion title="Indices" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Indice</th>
              <th>Statut</th>
              <th>Projet / Travaux</th>
            </tr>
          </thead>
          <tbody>
            {collection.indices_gep.map(indice => (
              <tr key={indice.id}>
                <td>{indice.indice}</td>
                <td>{indice.categorie}</td>
                <td>{indice.objet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      <div className="separator" />
      <Accordion title="Fiches" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Clé</th>
              <th>Indice</th>
              <th>Statut</th>
              <th>Résumé</th>
            </tr>
          </thead>
          <tbody>
            {collection.fiches.map(fiche => (
              <tr
                key={fiche.id}
                className="interactive"
                onClick={() => {
                  window.open(`https://${fiche.url}`, '_blank')
                }}
              >
                <td>{fiche.type}</td>
                <td>{fiche.key}</td>
                <td>{fiche.indice}</td>
                <td>{fiche.statut}</td>
                <td>{fiche.libelle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
    </div>
  )
}
